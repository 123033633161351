<template>
  <div class="startup-page">
    <StartupsPageContent
      :startup="startup"
      :jobs="jobs"
      :is-careers-page="true"
    />
  </div>
</template>

<script>
import StartupsPageContent from '@/components/startups/StartupsPageContent.vue'
import JobModel from '@/models/Job'
import { buildMetaHead } from '@/helpers/meta'

export default {
  name: 'PageStartupsCareers',
  layout: 'careers',
  components: {
    StartupsPageContent
  },
  data() {
    return {
      jobs: null
    }
  },
  computed: {
    startup() {
      return this.$store.state.startups.startup
    }
  },
  head() {
    return buildMetaHead({
      title: this.startup.name,
      description: this.startup.whatWeDo,
      // path: this.startup.url,
      path: this.startup.externalDomain,
      isPreview: true,
      imageUrl: this.startup.logoImage.thumbnailSizeUrl
    })
  },
  async asyncData({ req, store, $axios, error }) {
    const host = (req ? req.headers.host : window.location.host).replace(
      /^www\./,
      ''
    )

    try {
      await store.dispatch('startups/getByDomain', host)

      const { data: jobs } = await $axios.get(
        '/companies/' + store.state.startups.startup.key + '/jobs',
        {
          params: {
            limit: 100
          }
        }
      )

      return { jobs }
    } catch (err) {
      error(err)
    }
  },
  created() {
    this.jobs = {
      ...this.jobs,
      docs: this.jobs.docs.map((doc) => new JobModel(doc))
    }
  }
}
</script>
