<template>
  <div>
    <div class="startup-header">
      <div class="startup-header__logo">
        <img
          v-if="startup.logoImage"
          class="startup-header__logo__image"
          :src="startup.logoImage.thumbnailSizeUrl"
          :title="startup.name"
          :alt="startup.name"
        />

        <div v-else class="startup-header__logo__placeholder"></div>
      </div>
      <h2 class="startup-header__name">{{ startup.name || '-' }}</h2>
    </div>

    <b-row v-if="editable" class="startup-header__action">
      <b-col></b-col>
      <b-col cols="12" md="auto" class="d-flex justify-content-center">
        <n-link
          :to="`/client/startups/${startup.key}/edit`"
          class="btn btn-outline"
        >
          <img src="~/assets/img/icons/pencil.svg" alt="" />
          Edit startup profile
        </n-link>
      </b-col>
    </b-row>
    <b-row v-else class="startup-header__action">
      <b-col class="d-flex open-positions">
        <button
          v-if="openPositions > 0"
          class="btn btn-outline btn-w220 mr-10"
          @click="scrollToOpenPositions"
        >
          Open positions ({{ openPositions }})
        </button>
      </b-col>
      <b-col v-if="!isCareersPage" class="d-flex follow">
        <StartupsFollow :startup="startup" text @toggle="toggleFollow" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import StartupsFollow from './StartupsFollow'

export default {
  name: 'StartupsHeader',
  components: { StartupsFollow },
  props: {
    startup: Object,
    editable: Boolean,
    isCareersPage: {
      type: Boolean,
      default: false
    },
    openPositions: Number
  },
  methods: {
    scrollToOpenPositions() {
      if (this.openPositions && this.openPositions > 0) {
        var element = document.getElementById('open-positions')
        element.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    },
    async toggleFollow(value) {
      try {
        await this.$store.dispatch('startups/follow', {
          startup: this.startup,
          value
        })

        if (value) {
          this.$engagement({
            type: 'startup.follow'
          })
        }
      } catch (err) {
        console.error(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.startup-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 0.5rem;

  &__logo {
    line-height: 0;
    $size: 8rem;
    width: $size;
    height: $size;

    &__image {
      width: $size;
      height: $size;
      object-fit: cover;
    }

    &__placeholder {
      width: $size;
      height: $size;
      background: $gray-200;
    }
  }

  &__name {
    margin-top: 1.8rem;
    word-break: break-word;
    font-size: 2.7rem;
  }

  &__action {
    margin-top: rem(20px);
    margin-bottom: rem(-20px);

    img {
      padding-right: rem(20px);
      margin-left: rem(-10px);
      height: rem(16px);
      width: auto;
    }

    @include media-breakpoint-down(md) {
      flex-direction: column-reverse;
    }

    .open-positions {
      .btn {
        font-weight: 400;
      }

      @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: center;
        padding: rem(15px) 0;
      }
    }
    .follow {
      justify-content: flex-end;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}
</style>
