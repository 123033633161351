<template>
  <div>
    <div v-if="html" :class="classes" v-html="html"></div>
    <div v-else :class="classes">{{ text }}</div>
    <b-button
      v-if="isCollapsable"
      variant="link"
      class="mt-20"
      @click="showCollapse = !showCollapse"
      >Read {{ showCollapse ? 'less' : 'more' }}
      <i
        :class="[
          'icon',
          {
            'icon-caret': !showCollapse,
            'icon-caret-up': showCollapse
          }
        ]"
      ></i
    ></b-button>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String
    },
    html: {
      type: String
    },
    limit: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      showCollapse: false,
      isCollapsable: false
    }
  },
  computed: {
    classes() {
      return !this.showCollapse && this.isCollapsable
        ? ' lines lines-' + this.limit
        : ''
    }
  },
  beforeMount() {
    window.addEventListener('resize', this.setup)
  },
  mounted() {
    this.setup()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setup)
  },
  methods: {
    setup() {
      this.isCollapsable = false
      this.$nextTick(() => {
        const el = this.$el.children[0]
        const lineCount =
          el.getBoundingClientRect().height /
          parseInt(window.getComputedStyle(el).getPropertyValue('line-height'))
        this.isCollapsable = lineCount > this.limit
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-word;
}
@for $i from 1 through 20 {
  .lines-#{$i} {
    -webkit-line-clamp: $i;
  }
}
</style>
