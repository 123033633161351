<template>
  <div
    :class="[
      'gallery-images',
      {
        'gallery-images--ready': isReady
      }
    ]"
  >
    <div ref="mySwiperContainer" class="swiper-container">
      <div class="swiper-wrapper">
        <div v-for="(image, index) in images" :key="index" class="swiper-slide">
          <div v-if="image.extUrl">
            <b-modal v-model="isSuccessOpen" centered modal-class="modal-bb-video" hide-footer>
              <div class="modal-dialog">
                <div class="modal-content">
                  <iframe
                    width="560"
                    height="315"
                    :src="image.embedUrl"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </b-modal>
            <div @click="isSuccessOpen = true">
              <div class="circle">
                <div class="circle_inner"></div>
              </div>
              <img
                :src="image.imgPath"
                class="swiper-slide__video"
                :style="{
                  height: 'calc(' + height + 'px*var(--scale-gallery))'
                }"
                alt="gallery video"
              />
            </div>
          </div>
          <img
            v-else
            :src="image.coverSizeUrl"
            :style="{ height: 'calc(' + height + 'px*var(--scale-gallery))' }"
            alt="gallery image"
          />
        </div>
      </div>

      <div class="gallery-images__controls">
        <a
          class="gallery-images__controls__btn gallery-images__controls__btn--prev"
          @click="onPrev"
        >
          <i class="icon-arrow-2-left"></i>
        </a>
        <a
          class="gallery-images__controls__btn gallery-images__controls__btn--next"
          @click="onNext"
        >
          <i class="icon-arrow-2-right"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css';
import Swiper from 'swiper/dist/js/swiper.min.js';
import addRemovedHook from 'vue-removed-hook-mixin';

export default {
  name: 'StartupsGallery',
  mixins: [addRemovedHook],
  props: {
    images: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isReady: false,
      mobileBreakpointWidth: 480,
      height: 500,
      isSuccessOpen: false
    };
  },
  computed: {
    swiperOptions() {
      return {
        init: false,
        slidesPerView: 'auto',
        spaceBetween: 30,
        centerInsufficientSlides: true,
        watchOverflow: true,
        keyboard: {
          enabled: true
        },
        navigation: {
          nextEl: '.gallery-images__controls__btn--next',
          prevEl: '.gallery-images__controls__btn--prev'
        },
        breakpoints: {
          [this.mobileBreakpointWidth]: {
            slidesPerView: 1,
            spaceBetween: 20
          }
        }
      };
    }
  },
  watch: {
    images() {
      this.$nextTick(() => {
        this.mySwiper.update();
      });
    }
  },
  mounted() {
    this.mySwiper = new Swiper(this.$refs.mySwiperContainer, this.swiperOptions);
    this.mySwiper.on('init', () => {
      // console.log('init');
    });

    this.mySwiper.on('imagesReady', () => {
      // console.log('imagesReady')
      this.isReady = true;
    });

    this.mySwiper.on('resize', () => {
      if (window.innerWidth >= this.mobileBreakpointWidth) {
        // https://github.com/nolimits4web/Swiper/issues/2346
        this.mySwiper.slides.css('width', '');

        this.$nextTick(() => {
          this.mySwiper.update();
        });
      }
    });

    this.mySwiper.init();
  },
  removed() {
    // https://github.com/alvarotrigo/vue-fullpage.js/issues/81
    this.mySwiper.destroy();
  },
  methods: {
    onNext() {
      // http://idangero.us/swiper/api/#methods
      this.mySwiper.slideNext();
    },
    onPrev() {
      this.mySwiper.slidePrev();
    }
  }
};
</script>

<style lang="scss">
.gallery-images {
  position: relative;
  opacity: 0;
  transition: opacity 750ms ease-out;

  .swiper-slide {
    flex: 0 0 auto;
    width: auto;
    background: #fff;
    text-align: center;

    .circle {
      background: $gray-500;
      border-radius: 80px;
      height: 80px;
      position: absolute;
      z-index: 3;
      left: 50%;
      top: 50%;
      width: 80px;
      margin: -40px 0 0 -40px;
      cursor: pointer;
      border: 3px solid white;
    }
    .circle_inner {
      position: relative;
      height: 100%;
      cursor: pointer;
      z-index: 3;
    }
    .circle_inner:before {
      content: '';
      z-index: 3;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 15px 0 15px 30px;
      border-color: transparent transparent transparent #ffffff;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -15px 0 0 -10px;
    }

    &__video {
      cursor: pointer;
    }
  }

  &--ready {
    opacity: 1;
  }

  &__controls {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: rem(40px);
    padding-right: rem(40px);

    @include media-breakpoint-down(md) {
      display: none;
    }

    &__btn {
      position: relative;
      z-index: 4;
      height: rem(64px);
      width: rem(64px);
      border-radius: 50%;
      background: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-shadow: 0 20px 40px rgba($gray-900, 0.15) !important;
      text-decoration: none !important;
      outline: none;
      transform: translate(0, -50%);

      i {
        color: $gray-600;
        font-size: rem(20px);
        @include transition();
      }

      &:hover {
        box-shadow: 0 20px 40px rgba($gray-900, 0.2) !important;

        i {
          color: $gray-900;
        }
      }

      &.swiper-button-disabled {
        opacity: 0;
        pointer-events: none;
      }

      &.swiper-button-lock {
        display: none;
      }
    }
  }
}
</style>
