<template>
  <div class="startup-key-figures">
    <h4 class="startup-key-figures__title">Key numbers</h4>

    <b-row>
      <b-col
        v-for="figure in keyFigures"
        :key="figure.name"
        md="4"
        class="startup-key-figures__item"
      >
        <h2 class="startup-key-figures__value">
          <span v-if="!isType(figure, 'ratio')"
            >{{ isType(figure, 'money') ? '€' : ''
            }}{{ figure.value | approx({ round: true }) }}</span
          >
          <span v-else>{{ figure.value }}/{{ 100 - figure.value }}</span>
        </h2>
        <h4 class="startup-key-figures__label">{{ getFigureLabel(figure) }}</h4>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'StartupsKeyFigures',
  props: {
    keyFigures: Array
  },
  computed: {
    keyFigureNames() {
      return this.$store.getters['meta/companyKeyFigureNames']
    }
  },
  methods: {
    getFigureData(figure) {
      return this.keyFigureNames.find((item) => item.value === figure.name)
    },
    getFigureLabel(figure) {
      const option = this.getFigureData(figure)
      return option ? option.publicLabel : ''
    },
    isType(figure, type) {
      const option = this.getFigureData(figure)

      return option && option.type === type
    }
  }
}
</script>

<style lang="scss" scoped>
.startup-key-figures {
  &__title {
    font-size: 1.25rem;
  }

  &__item {
    padding-top: 3rem;
  }

  &__value {
    font-size: 5rem;
  }

  &__label {
    font-size: 1.2rem;
    color: $gray-700;
  }
}
</style>
