var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'gallery-images',
    {
      'gallery-images--ready': _vm.isReady
    }
  ]},[_c('div',{ref:"mySwiperContainer",staticClass:"swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.images),function(image,index){return _c('div',{key:index,staticClass:"swiper-slide"},[(image.extUrl)?_c('div',[_c('b-modal',{attrs:{"centered":"","modal-class":"modal-bb-video","hide-footer":""},model:{value:(_vm.isSuccessOpen),callback:function ($$v) {_vm.isSuccessOpen=$$v},expression:"isSuccessOpen"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_c('iframe',{attrs:{"width":"560","height":"315","src":image.embedUrl,"frameborder":"0","allow":"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})])])]),_vm._v(" "),_c('div',{on:{"click":function($event){_vm.isSuccessOpen = true}}},[_vm._m(0,true),_vm._v(" "),_c('img',{staticClass:"swiper-slide__video",style:({
                height: 'calc(' + _vm.height + 'px*var(--scale-gallery))'
              }),attrs:{"src":image.imgPath,"alt":"gallery video"}})])],1):_c('img',{style:({ height: 'calc(' + _vm.height + 'px*var(--scale-gallery))' }),attrs:{"src":image.coverSizeUrl,"alt":"gallery image"}})])}),0),_vm._v(" "),_c('div',{staticClass:"gallery-images__controls"},[_c('a',{staticClass:"gallery-images__controls__btn gallery-images__controls__btn--prev",on:{"click":_vm.onPrev}},[_c('i',{staticClass:"icon-arrow-2-left"})]),_vm._v(" "),_c('a',{staticClass:"gallery-images__controls__btn gallery-images__controls__btn--next",on:{"click":_vm.onNext}},[_c('i',{staticClass:"icon-arrow-2-right"})])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"circle"},[_c('div',{staticClass:"circle_inner"})])}]

export { render, staticRenderFns }