<template>
  <div class="card-investor" :class="{ clickable: !isCareersPage }">
    <a
      v-if="!isCareersPage"
      :href="investor.getUrl($store)"
      target="_blank"
    ></a>

    <div class="image">
      <UserAvatar
        size="investor"
        class="image-resource"
        :image="investor.logoImageUrl"
        :name="investor.displayName"
        :email="investor.displayName"
        transparent
      />
    </div>

    <div class="category">
      {{ investor.displayCategory }}
    </div>
    <h4 class="name">
      {{ investor.displayName }}
    </h4>

    <p v-if="investor.intro">{{ investor.intro }}</p>
  </div>
</template>

<script>
import UserAvatar from '@/components/user/UserAvatar'

export default {
  name: 'StartupsInvestorCard',
  components: {
    UserAvatar
  },
  props: {
    investor: {
      type: Object,
      required: true
    },
    isCareersPage: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.card-investor {
  position: relative;
  padding: 20px;
  transition: box-shadow 0.3s;

  &.clickable {
    &:hover,
    &:focus,
    &:active {
      box-shadow: $box-shadow-light;
    }
  }

  a {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .image {
    margin-bottom: rem(20px);
  }

  .category {
    font-size: rem(12px);
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: rem(10px);
  }

  .name,
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .name {
    margin-bottom: rem(10px);
    max-height: rem(52px);
  }

  p {
    max-height: rem(44px);
    color: $gray-600;
  }
}
</style>
