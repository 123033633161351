<template>
  <div>
    <b-row>
      <b-col>
        <div class="sdgs">
          <div v-for="(sdg, i) in visibleSdgs" :key="i" class="sdg">
            <img
              :src="
                require(`@/assets/components/sdgs/active/${getSdgImage(
                  sdg
                )}.svg`)
              "
              alt="sdg"
            />
          </div>
        </div>
      </b-col>
    </b-row>

    <a v-if="shouldShowMoreButton" class="more" @click="isShowingAll = true"
      >Show more <i class="icon icon-caret"></i
    ></a>
  </div>
</template>

<script>
export default {
  name: 'StartupsSdgs',
  props: {
    list: {
      type: Array,
      required: true
    },
    max: {
      type: Number,
      default: 9
    }
  },
  data: () => ({
    isShowingAll: false
  }),
  computed: {
    sdgs() {
      return this.$store.getters['meta/sdgs']
    },
    shouldShowMoreButton() {
      return this.max < this.list.length && !this.isShowingAll
    },
    visibleSdgs() {
      return this.isShowingAll ? this.list : this.list.slice(0, this.max)
    }
  },
  methods: {
    getSdgImage(sdg) {
      return this.sdgs.findIndex((item) => item.value === sdg) + 1
    }
  }
}
</script>

<style lang="scss" scoped>
.sdgs {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;

  .sdg {
    margin: 5px;
  }
}

.more {
  margin-top: 2rem;
  display: inline-block;
  font-weight: 700;
  color: $blue-900 !important;
  cursor: pointer;

  & > i {
    font-size: 0.7rem;
    margin-left: 0.3rem;
  }
}
</style>
