<template>
  <div :class="['card-person', { 'card-person--small': small }]">
    <div class="card-person__image">
      <UserAvatar
        size="profile"
        class="card-person__image__resource"
        :image="person.image ? person.image.thumbnailSizeUrl : null"
        :name="`${person.name.first} ${person.name.last}`"
        :email="person.title"
      />

      <a
        v-if="person.linkedinUrl"
        target="_blank"
        :href="person.linkedinUrl"
        class="card-person__linkedin"
        ><i class="icon-linkedin"></i
      ></a>
    </div>

    <div v-if="person.title" class="card-person__position">
      <span v-if="person.isFounder">Founder, </span>{{ person.title }}
    </div>
    <h4 v-if="person.name" class="card-person__name">
      {{ person.name.first }} {{ person.name.last }}
    </h4>
  </div>
</template>

<script>
import UserAvatar from '@/components/user/UserAvatar'

export default {
  name: 'CardPerson',
  components: {
    UserAvatar
  },
  props: {
    person: {
      type: Object,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.card-person {
  &__image {
    position: relative;
    margin-bottom: rem(20px);

    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    &__resource {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
    }
  }

  &__linkedin {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: rem(10px);
    background-color: $white;
    color: $blue-900;
    line-height: 1;

    i {
      font-size: rem(20px);
    }

    &:hover,
    &:active,
    &:focus {
      color: $blue-700;
    }
  }

  &__position {
    font-size: rem(12px);
    font-weight: 700;
    text-transform: uppercase;
    color: $gray-600;
    margin-bottom: rem(10px);
  }

  &__name {
    margin-bottom: rem(10px);
  }

  &__description {
    margin-bottom: rem(15px);
    color: $gray-700;
    padding-right: 10%;
  }

  &__location {
    display: flex;
    align-items: center;
    font-size: rem(14px);
    line-height: 1;
    font-weight: 500;
    margin-bottom: rem(10px);
  }
}
</style>
