<template>
  <div class="company-form-preview">
    <div v-if="isInstantClaimAvailable">
      <div class="bg-primary text-center text-white p-3">
        <button class="btn btn-link text-white" @click="openInstantClaimModal">
          <b><u>Claim this startup</u></b>
        </button>
        and take ownership of it and its job offers
      </div>
    </div>
    <div v-else-if="startup.claim">
      <div class="bg-primary text-center text-white p-3">
        <button class="btn btn-link text-white" @click="openClaimModal">
          <b><u>Claim this startup</u></b>
        </button>
        and take ownership of it and its job offers
      </div>
    </div>
    <Container width="medium" spacing="medium" border-bottom>
      <StartupsHeader
        :startup="startup"
        :editable="editable"
        :is-careers-page="isCareersPage"
        :open-positions="jobs.total"
      />
    </Container>

    <div class="mb-40">
      <Container width="medium" spacing="medium">
        <StartupsDetails :startup="startup" :is-careers-page="isCareersPage" />
      </Container>

      <Container
        v-if="startup.galleryImages || startup.video"
        class="mb-60"
        width="big"
        spacing="small"
        plr-none
      >
        <StartupsGallery :images="mergedImages" :height="500" />
      </Container>

      <Container
        v-if="startup.perks && startup.perks.length"
        width="medium"
        spacing="medium"
        class="mb-20"
      >
        <TextBlock class="mt-0">
          <h3 class="fs40 mb-10">Working at <br />{{ startup.name }}</h3>
          <p class="text-gray-700 pb-30 pb-lg-40">
            This job comes with several perks and benefits
          </p>

          <StartupsBenefits :list="startup.perks" :max="6" />
        </TextBlock>
      </Container>
      <Container
        v-if="startup.quote && startup.quote.text"
        width="medium"
        spacing="medium"
        class="mb-10"
        border-top
      >
        <StartupsQuoteImage :quote="startup.quote" />
      </Container>

      <Container
        v-if="startup.keyFigures && startup.keyFigures.length > 0"
        width="medium"
        spacing="medium"
        border-top
        class="mb-20"
      >
        <StartupsKeyFigures :key-figures="startup.keyFigures" />
      </Container>

      <Container
        v-if="startup.sdgs && startup.sdgs.length"
        width="medium"
        spacing="medium"
        border-top
        class="mb-30"
      >
        <h2 class="fs40 pb-20 pb-md-40">SDGs</h2>

        <StartupsSdgs :list="startup.sdgs" />
      </Container>

      <Container v-if="team.length" width="medium" spacing="medium" border-top class="mb-30">
        <h2 class="fs40 pb-20 pb-md-40">Team</h2>
        <b-row>
          <b-col v-for="(person, i) in team" :key="i" lg="3" md="4" cols="6" class="mb-20">
            <CardPerson :key="i" :person="person" />
          </b-col>
        </b-row>
      </Container>

      <Container
        v-if="startup.investors && startup.investors.length"
        width="medium"
        spacing="medium"
        border-top
        class="mb-30"
      >
        <StartupsInvestors :investors="startup.investors" :is-careers-page="isCareersPage" />
      </Container>
    </div>

    <Container
      v-if="jobs && jobs.total > 0"
      id="open-positions"
      width="medium"
      background-color="#F5F7FA"
    >
      <h3 class="fs40 mb-40">Open positions ({{ jobs.total }})</h3>
      <div v-for="job in jobs.docs" :key="job.id" class="my-10">
        <JobsListItem :key="job.id" :job="job" :is-careers-page="isCareersPage" />
      </div>
    </Container>

    <div v-if="isInstantClaimAvailable">
      <StartupInstantClaim
        ref="instantClaimStartupModal"
        :startup="startup"
        button-text="Claim startup"
      />
    </div>
    <div v-else-if="startup.claim">
      <StartupClaim ref="claimStartupModal" :startup="startup" button-text="Claim startup" />
    </div>
  </div>
</template>

<script>
import Container from '@/components/Container';
import StartupsGallery from './StartupsGallery';
import StartupsQuoteImage from '@/components/startups/StartupsQuoteImage';
import CardPerson from '@/components/CardPerson';
import JobsListItem from '@/components/jobs/JobsListItem';
import TextBlock from '@/components/TextBlock';
import StartupsBenefits from './StartupsBenefits';
import StartupsHeader from './StartupsHeader';
import StartupsDetails from './StartupsDetails';
import StartupsKeyFigures from './StartupsKeyFigures';
import StartupsInvestors from './StartupsInvestors';
import StartupsSdgs from './StartupsSdgs';
import StartupClaim from '@/components/startups/StartupClaim';
import StartupInstantClaim from '@/components/startups/StartupInstantClaim';

export default {
  name: 'StartupsPageContent',
  components: {
    StartupsKeyFigures,
    Container,
    StartupsGallery,
    StartupsQuoteImage,
    CardPerson,
    JobsListItem,
    TextBlock,
    StartupsHeader,
    StartupsDetails,
    StartupsBenefits,
    StartupsInvestors,
    StartupsSdgs,
    StartupClaim,
    StartupInstantClaim
  },
  props: {
    editable: Boolean,
    startup: {
      type: Object,
      required: true
    },
    jobs: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isCareersPage: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    team() {
      const list = this.startup.team || [];

      const team = list.filter((item) => !item.isFounder);
      const founders = list.filter((item) => item.isFounder);

      // needed for startup create form
      if (this.startup.founders) {
        founders.push(...this.startup.founders);
      }

      return founders.concat(team);
    },
    isInstantClaimAvailable() {
      return (
        this?.$route?.path?.endsWith('/welcome') && this.startup.instantClaimStatus === 'AVAILABLE'
      );
    },
    mergedImages() {
      var imageArr = this.startup.galleryImages || [];
      var videoArr = [];
      if (this.startup.video) {
        let video = {};
        let id = this.getYoutubeId(this.startup.video);
        video['id'] = id;
        video['imgPath'] = `${'https://img.youtube.com/vi/' + id + '/hqdefault.jpg'}`;
        video['embedUrl'] = 'https://www.youtube.com/embed/' + id + '?&autoplay=1&modestbranding=1';
        video['extUrl'] = this.startup.video;
        videoArr.push(video);
      }
      const arrays = [...videoArr, ...imageArr];
      return arrays;
    }
  },
  methods: {
    getYoutubeId(url) {
      const arr = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      return undefined !== arr[2] ? arr[2].split(/[^\w-]/i)[0] : arr[0];
    },
    openClaimModal() {
      this.$refs.claimStartupModal.openClaimModal();
    },
    openInstantClaimModal() {
      this.$refs.instantClaimStartupModal.openClaimModal();
    }
  }
};
</script>
