<template>
  <div class="component-quote-image">
    <img
      src="~/assets/components/startups/quote-image/pattern.svg"
      class="component-quote-image__pattern"
      alt=""
    />
    <div
      v-if="quote.image"
      :style="{
        backgroundImage: 'url(' + quote.image.portaitSizeUrl + ')',
        height: 'calc(' + imgHeight / 16 + 'rem*var(--scale-spacing))'
      }"
      class="component-quote-image__image"
    ></div>
    <div class="component-quote-image__text">
      <h2
        class="component-quote-image__title pb-20"
        :class="{
          'component-quote-image__title__long': quote.text.length > 200
        }"
      >
        “{{ quote.text }}”
      </h2>
      <div class="component-quote-image__position">
        <b>{{ quote.author }},</b>
        {{ quote.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StartupsQuoteImage',
  props: {
    quote: {
      type: Object,
      required: true
    },
    imgHeight: {
      type: Number,
      default: 560
    }
  }
}
</script>

<style lang="scss">
.component-quote-image {
  position: relative;
  display: flex;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  &__pattern {
    position: absolute;
    top: 50%;
    right: calc(100% + 1.25rem);
    transform: translateY(-50%);
  }

  &__image {
    display: block;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 560px;
    width: calc(50% - 1.25rem);
    flex: 0 0 calc(50% - 1.25rem);
    margin-right: 2.5rem;

    @include media-breakpoint-down(sm) {
      height: 200px;
      width: 100%;
      flex: 1 1 auto;
      margin-right: 0;
      margin-bottom: 1.5rem;
    }
  }

  &__text {
    width: calc(50% - 1.25rem);
    flex: 0 0 calc(50% - 1.25rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
      width: 100%;
      flex: 0 0 calc(100%);
    }
  }

  &__title {
    max-width: 90%;
    line-height: 1.25;

    &__long {
      font-size: 2rem;

      @include media-breakpoint-down(lg) {
        font-size: calc(0.75rem + 1.5vw);
      }

      @include media-breakpoint-down(sm) {
        font-size: calc(1.25rem + 2.1vw);
      }
    }

    @include media-breakpoint-down(md) {
      max-width: 100%;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 1.5rem;
    }
  }

  &__position {
    color: $gray-700;
    line-height: 1.5;
    font-size: 1.1rem;

    b {
      display: block;
      color: $gray-900;
    }
  }
}
</style>
