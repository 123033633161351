<template>
  <div class="startup-investors">
    <h2 class="fs40 pb-20 pb-md-40">Investors</h2>

    <b-row>
      <b-col
        v-for="(investor, i) in visibleInvestors"
        :key="i"
        lg="3"
        md="4"
        cols="6"
        class="mb-20"
      >
        <StartupsInvestorCard
          :key="i"
          :investor="investor"
          :is-careers-page="isCareersPage"
        />
      </b-col>
    </b-row>

    <a v-if="hasMoreInvestors" class="more" @click="showAll = !showAll">
      {{ !showAll ? `See all ${investors.length} investors` : 'Show less' }}
      <i
        class="icon"
        :class="{ 'icon-caret': !showAll, 'icon-caret-up': showAll }"
      ></i>
    </a>
  </div>
</template>

<script>
import StartupsInvestorCard from './StartupsInvestorCard'

export default {
  name: 'StartupsInvestors',
  components: {
    StartupsInvestorCard
  },
  props: {
    investors: Array,
    isCareersPage: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showAll: false
  }),
  computed: {
    sortedInvestors() {
      return this.investors.slice(0).sort((a, b) => {
        const aIsRegistered = !!a.investor
        const bIsRegistered = !!b.investor

        if (aIsRegistered !== bIsRegistered) {
          return aIsRegistered ? -1 : 1
        }

        return 0
      })
    },
    visibleInvestors() {
      return this.showAll
        ? this.sortedInvestors
        : this.sortedInvestors.slice(0, 8)
    },
    hasMoreInvestors() {
      return this.investors.length > 8
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.startup-investors {
  .more {
    margin-top: 2rem;
    display: inline-block;
    font-weight: 700;
    color: $blue-900 !important;
    cursor: pointer;

    & > i {
      font-size: 0.7rem;
      margin-left: 0.3rem;
    }
  }
}
</style>
