<template>
  <TextBlock class="details mt-0 mb-20 mb-lg-30">
    <b-row>
      <b-col lg="7">
        <div class="details__text">
          <ExpandableText
            v-if="startup.whatWeDo"
            class="details__text__content"
            :text="startup.whatWeDo"
            :limit="10"
          />
        </div>
      </b-col>

      <b-col class="details__info" lg="5">
        <table class="key-value-list">
          <tbody>
            <tr>
              <td class="key-value-list__key">Location</td>
              <td class="key-value-list__value">
                <b class="text-gray-900">{{ formattedLocations }}</b>
              </td>
            </tr>
            <tr v-if="!isCareersPage">
              <td class="key-value-list__key">Website</td>
              <td class="key-value-list__value">
                <a
                  :href="startup.websiteUrl"
                  target="_blank"
                  class="text-blue-900"
                  ><b>{{ formattedWebsite || '-' }}</b></a
                >
              </td>
            </tr>
            <tr>
              <td class="key-value-list__key">Founded</td>
              <td class="key-value-list__value">
                <b class="text-gray-900">{{ startup.founded || '-' }}</b>
              </td>
            </tr>
            <tr>
              <td class="key-value-list__key">Employees</td>
              <td class="key-value-list__value">
                <b class="text-gray-900">{{
                  startup.numberOfEmployees || '-'
                }}</b>
              </td>
            </tr>
            <tr>
              <td class="key-value-list__key">Industries</td>
              <td class="key-value-list__value">
                <b class="text-gray-900">{{
                  formattedIndustriesText || '-'
                }}</b>
              </td>
            </tr>
            <tr>
              <td class="key-value-list__key">Business model</td>
              <td class="key-value-list__value">
                <b class="text-gray-900">{{ businessModelText || '-' }}</b>
              </td>
            </tr>
            <tr>
              <td class="key-value-list__key">Funding state</td>
              <td class="key-value-list__value">
                <b class="text-gray-900">{{ fundingStateText || '-' }}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
  </TextBlock>
</template>

<script>
import url from 'url'
import TextBlock from '@/components/TextBlock'
import ExpandableText from '@/components/ExpandableText'

export default {
  name: 'StartupsDetails',
  components: {
    TextBlock,
    ExpandableText
  },
  props: {
    startup: {
      type: Object,
      required: true
    },
    isCareersPage: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formattedLocations() {
      if (!this.startup.countries || !this.startup.countries.length) {
        return '-'
      }

      return this.startup.countries
        .map((country) => country.location.countryName)
        .join(', ')
    },
    formattedIndustriesText() {
      if (!this.startup.industries) {
        return
      }

      const industries = this.startup.industries.map((industry) =>
        this.$store.getters['meta/industryByValue'](industry)
      )

      return industries.map((industry) => industry.label).join(', ')
    },
    formattedWebsite() {
      if (!this.startup.website) {
        return null
      }

      const parts = url.parse(this.startup.websiteUrl)
      return parts.hostname && parts.hostname.replace(/^www\./, '')
    },
    businessModelText() {
      const businessModels = this.$store.getters['meta/companyBusinessModels']
      return businessModels
        .filter((item) => {
          return (this.startup.businessModels || []).indexOf(item.value) > -1
        })
        .map((item) => item.label)
        .join(', ')
    },
    fundingStateText() {
      if (!this.startup.fundingStage) {
        return
      }
      const fundingStage = this.$store.getters[
        'meta/companyFundingStagesByValue'
      ](this.startup.fundingStage)
      return fundingStage && fundingStage.label
    }
  }
}
</script>

<style lang="scss" scoped>
.key-value-list {
  width: 100%;

  tr {
    td {
      padding: 0.5rem 0;
      line-height: 1.5;
    }

    &:first-child {
      td {
        padding-top: 0.075rem;
      }
    }
  }

  &__key {
    vertical-align: top;
    padding-right: 1rem;
    min-width: 160px;
  }

  &__value {
    vertical-align: top;
    text-align: right;
  }
}

.details {
  &__text {
    &__content {
      max-width: 620px;
      white-space: pre-line;
    }
  }

  @include media-breakpoint-down(md) {
    &__text {
      margin-bottom: 2.5rem;
      padding-bottom: 2rem;
      border-bottom: 1px solid $border-color;
    }

    &__info {
      margin-bottom: 1.5rem;
    }
  }
}
</style>
