<template>
  <button v-if="userIsLoggedIn" @click="$emit('toggle', !startup.followed)">
    <span v-if="text">
      {{ startup.followed ? 'You follow' : 'Follow' }} {{ startup.name }}
    </span>

    <span class="icon">
      <img
        v-if="startup.followed"
        src="~/assets/components/startups/followed.svg"
        alt=""
      />
      <img v-else src="~/assets/components/startups/follow.svg" alt="" />
    </span>
  </button>
</template>

<script>
export default {
  name: 'StartupsFollow',
  props: {
    startup: {
      required: true,
      type: Object
    },
    text: Boolean
  },
  computed: {
    userIsLoggedIn() {
      return this.$store.getters['user/isLoggedIn']
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  position: relative;
  z-index: 11;
  display: flex;
  align-items: center;
  color: $gray-700;

  .icon {
    margin-left: rem(10px);
    margin-top: rem(-5px);
  }
}
</style>
